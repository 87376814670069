import InterviewClient from './InterviewClient';

import '../css/styles.css';

class App {
	constructor( config ) {
		config = this.settings = this.parseConfig( config );
		this.interview = new InterviewClient( config );

		if ( config.audio ) {
			this.playAudio( config );
		}

		if ( config.talk_trigger ) {
			console.log( 'binding', config.talk_trigger );
			this.hotkeyListener( config.talk_trigger );
		}

		if ( config.redirect ) {

			if ( config.redirect_delay ) {
				console.log( 'Setting redirect timer' );
				setTimeout( () => { location.href = config.redirect }, config.redirect_delay );
			} else {
				location.href = config.redirect;
				return;
			}
		}

	}

	parseConfig( settings ) {
		const defaults = {
			audioinit: false,
			interview: null,
			start: false,
			audio: null,
			after_audio: null,
			talk_trigger: null,
			redirect: null,
			redirect_delay: null
		};

		const config = {};

		for( const prop in defaults ) {
			config[prop] = ( prop in settings ) ? settings[prop] : defaults[prop];
		}

		return config;
	}

	playAudio( config ) {
		const audio = new Audio( config.audio );
		
		if ( config.after_audio ) {
			audio.addEventListener( 'ended', () => {
				location.href = config.after_audio;
			});
		}

		audio.play()
			.catch( (e) => {
				const dialog = document.querySelector( '#talk_notice' );
				this.hotkeyListener( () => {
					dialog && dialog.close();
					audio.play();
				});

				dialog && dialog.showModal();

			});
	}

	hotkeyListener( down, up, keycode ) {
		keycode = keycode || 'Space';
		if ( down ) {
			document.addEventListener( 'keydown', e => {
				if ( e.code == keycode ) {
					e.preventDefault();
					if ( e.repeat ) { return; }
					down();
				}
			});
		}

		if ( up ) {
			document.addEventListener( 'keyup', e => {
				if ( e.code == keycode ) {
					e.preventDefault();
					up();
				}
			});
		}
	}
}

document.addEventListener('DOMContentLoaded', () => {
	new App( global.synapse_settings || {} );
});


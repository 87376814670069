import { AudioCapture } from './AudioCapture';
import { SkylarAPI } from './SkylarAPI';
import AgentVisualizer from './Control/AgentVisualizer';
import SpeakerVisualizer from './Control/SpeakerVisualizer';

// @todo: Migrate to server.
import { SpeechRecognition } from './SpeechRecognition';
import { TextToSpeech } from './TextToSpeech';

class InterviewClient {

	constructor( config ) {
		this.audioCapture = new AudioCapture( config );
		this.speechRecognition = new SpeechRecognition( process.env.AZURE_SPEECH_KEY, process.env.AZURE_SPEECH_REGION );
		this.textToSpeech = new TextToSpeech(process.env.ELEVENLABS_API_KEY, process.env.ELEVENLABS_VOICE_ID );
		this.api = new SkylarAPI();
		this.starting_interview = true;
		this.config = config;

		if ( config.interview ) {
			this.setupElements();
			this.subject = config.interview;
			this.setupTriggers();

			if ( config.start ) {
				this.sendMessage( config.start );
			}
		}
	}

	setupElements() {
		this.outputElement = document.querySelector( '#agent_output' );
		this.visualizer = new AgentVisualizer( document.querySelector( '#audio_anim' ) );
		this.volumeMeter = new SpeakerVisualizer( document.querySelector( '#speaker_visualizer' ) );
	}

	setupTriggers() {
		document.addEventListener( 'keydown', e => {
			if ( e.code == 'Space' ) {
				e.preventDefault();
				if ( e.repeat ) { return; }
				this.startListening();
			}
		});
		document.addEventListener( 'keyup', e => {
			if ( e.code == 'Space' ) {
				e.preventDefault();
				this.stopListening();
			}
		});
	}


	async startListening() {
		try {
			this.updateStatus('Listening...');
			await this.audioCapture.start();

			this.volumeMeter.setSource( this.audioCapture.stream );
			this.volumeMeter.setSpeaking( true );

		} catch (error) {
			this.updateStatus('Error starting audio capture');
			console.error(error);
			this.volumeMeter.setSpeaking( false );
		}
	}

	async stopListening() {
		try {
			this.audioCapture.stop( async (audioBlob) => {
				await this.recognizeAudio( audioBlob );
			});

			this.volumeMeter.setSpeaking( false );

			this.updateStatus('Processing...');
		} catch (error) {
			this.updateStatus('Error processing audio');
			console.error(error);

		}
	}

	// @todo: Move to server.
	async recognizeAudio( audioBlob ) {

		this.updateStatus( 'Sending audio' );
		this.visualizer.setThinking( true );

		try {
			const file = new File( [audioBlob], 'input.wav' );
			const recognizedText = await this.speechRecognition.recognizeFromAudioFile( file );

			if ( recognizedText.trim().length ) {
				this.updateStatus( 'Sending recognized text:', recognizedText );
				this.sendMessage( recognizedText );
			} else {
				this.updateStatus('No speech detected');
			}
		} catch( error ) {
			this.updateStatus( 'Error recognizing audio' );
			console.error( error );
			this.visualizer.setThinking( false );
		}

	}

	async sendMessage( text ) {
		const response = await this.sendTextToAgent(text, this.starting_interview ? this.subject : false );
		let endAction = null;

		if ( this.starting_interview ) {
			this.starting_interview = false;
		}

		if ( response.text ) {
			if ( response.text.indexOf( '<wrap_up>' ) !== -1 ) {
				console.log( 'Wrapping up.' );
				response.text = response.text.replace( '<wrap_up>', '' );

				endAction = () => {
					console.log( 'Setting wrapup timer' );
					setTimeout( () => {
						location.href = '/end';
					}, 15000 );
				};
			}
		}

		// Handle special control messages here.
		if ( response.redirect ) {

			if ( response.redirect_delay ) {
				setTimeout( () => { location.href = response.redirect }, response.redirect_delay );
			} else {
				location.href = response.redirect;
				return;
			}
		}

		if ( response.text ) {
			await this.synthesizeSpeech( response.text, endAction );
		}
	}

	async sendTextToAgent( message, reset_subject ) {
		return this.api.userConversation( message, reset_subject );
	}

	async synthesizeSpeech( message, afterDone ) {
		this.visualizer.setThinking( true );
		//const audiodata = await this.textToSpeech.synthesizeSpeech( message );

		const { audiodata, messagedata } = await this.textToSpeech.streamSpeech( message );
		//this.displayText( message );

		// Playback
		const url = URL.createObjectURL( audiodata );
		const audio = new Audio( url );
		audio.addEventListener( 'ended', () => {
			this.visualizer.setSpeaking( false );

			if ( afterDone ) {
				afterDone();
			}
		});

		// Buffering
		audio.addEventListener( 'waiting', () => {
			this.visualizer.setThinking( true );
		});

		// Playback resumes.
		audio.addEventListener( 'playing', () => {
			this.visualizer.setThinking( false );
		});

		audio.addEventListener( 'timeupdate', () => {
			if ( !this.outputElement ) {
				return;
			}

			const time = audio.currentTime;
			let text = '';

			const { segment, start } = messagedata;
	
			for( let i=0,n=segment.length; i<n; i++ ) {
				if ( time < start[i] ) {
					break;
				}

				text += segment[i];
			}

			const el = this.outputElement;
			el.textContent = text;
			el.scrollTop = el.scrollHeight;

		});
	
		this.visualizer.setSource( audio );
		this.visualizer.setSpeaking( true );
		audio.play();
	}

	updateStatus( message ) {
		console.log( 'Status:', message );
	}

	displayText( message ) {
		if ( this.outputElement ) {
			this.outputElement.textContent = message;
		}
	}

}

export default InterviewClient;